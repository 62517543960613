import DefaultLayout from './default';
import BlankLayout from './blank';
import ConnectLayout from './connect';

const layouts = {
  default: DefaultLayout,
  connect: ConnectLayout,
  blank: BlankLayout,
};

export default layouts;
