import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch, useLocation} from "react-router-dom";
import routes from './routes';
import layouts from './layouts';
import PrivateRoute from "./wrappers/PrivateRoute"
import {QueryClient, QueryClientProvider} from 'react-query';
import {ToastContainer} from "react-toastify";


const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const queryClient = new QueryClient();


// Check if main content exists, then scroll to top on mount.
const ScrollToTop = ({children}) => {
    let location = useLocation();

    useEffect(() => {
        const mainContent = document.getElementById('main-content');

        if (mainContent) {
            mainContent.scrollTo(0, 0);
        }
    }, [location])

    return children;
}




function App() {

    useEffect(() => {
        //document.title = 'Dlex React Nile Dashboard';
    }, [])



    const routesWithLayouts = routes.map(route => {
        // define default layout
        if (!route.layout) {
            route.layout = 'default';
        }
        if (route.title) {
            document.title = route.title;
        }
        return route;
    });
    const routesGroupedByLayouts = groupBy(routesWithLayouts, 'layout');

    return (
        <Router>
            <Switch>
                {Object.keys(routesGroupedByLayouts).map(layoutName => {
                    const LayoutTag = layouts[layoutName];
                    const layoutRoutes = routesGroupedByLayouts[layoutName].map(route => route.path);

                    return (
                        <Route key={`layout-${layoutName}`} exact={true} path={layoutRoutes}>
                            <QueryClientProvider client={queryClient}>
                                <ScrollToTop>
                                    <LayoutTag>
                                                                                    <ToastContainer />

                                        <Switch>
                                            {routesGroupedByLayouts[layoutName].map(route => {
                                                const RouteType = route.requiresAuth ? PrivateRoute : Route;

                                                return (
                                                    <RouteType
                                                        key={route.path}
                                                        path={route.path}
                                                        exact={route.exact === true}
                                                        component={route.component}
                                                    />
                                                );
                                            })}
                                        </Switch>
                                    </LayoutTag>
                                </ScrollToTop>
                            </QueryClientProvider>
                        </Route>
                    );
                })}
            </Switch>
        </Router>
    );
}

export default App;
