import { useEffect, useState } from "react";

const configFile = '/config/categories.json'

export function useCategory() {
    const [config, setConfig] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function fetchConfig() {
            try {
                const response = await (await fetch(configFile)).json();
                setConfig(response);
                setLoading(false);
            } catch (e) {
                console.log(e);
            }
        }());
    }, []);

    return [config, loading];
}