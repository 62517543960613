import React from "react";
import {Layout} from "../@dlex/components";

const Default = ({children}) => {

  return (
    <Layout>
      <Layout.Header>
        {/* Top Navbar */}
      </Layout.Header>
      {/* Main Content */}
      <Layout.Body id={"main-content"}>
        {children}
      </Layout.Body>
      {/* Side Navigation */}
    </Layout>
  )
}

export default Default;
