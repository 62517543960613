import { Route, Redirect } from 'react-router-dom';
import PocketBase from "pocketbase";




const PrivateRoute = ({component: Component, ...rest}) => {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
    const isAuthenticated = pb.authStore.isValid;
    return (
        <Route {...rest} render={props => (
            isAuthenticated ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoute