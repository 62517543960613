import React, {useEffect, useRef, useState} from 'react';
import classnames from "classnames";
import PropTypes from 'prop-types';
import Sidenav from '../libs/SideNav.lib'
import SidenavContext from '../context/sidenav';
import NavMenu from './NavMenu'
import '../scss/_sidenav.scss'

const propTypes = {
  compact: PropTypes.bool,
  compactAutoExpandOnHover: PropTypes.bool,
  compactAutoExpandDelay: PropTypes.number,
  drawer: PropTypes.bool,
  direction: PropTypes.string,
  drawerPageOverlay: PropTypes.bool,
  drawerCloseOnEsc: PropTypes.bool,
  drawerClose: PropTypes.string,
  trigger: PropTypes.string,
  triggerMode: PropTypes.string,
  animation: PropTypes.bool,
  animationDuration: PropTypes.number,
  width: PropTypes.number,
  compactWidth: PropTypes.number
}

const Header = ({ className, children }) => {
  return (
    <div className={classnames('sn-header', className)}>
      {children}
    </div>
  );
};

const Body = ({ className, children }) => {
  return (
    <div className={classnames('sn-content', className)}>
      {children}
    </div>
  );
};

const Footer = ({ className, children }) => {
  return (
    <div className={classnames('sn-footer', className)}>
      {children}
    </div>
  );
};

const Nav = ({
  className,
  children,
  compact = false,
  compactAutoExpandOnHover = false,
  compactAutoExpandDelay = 300,
  drawer = false,
  direction = 'left',
  drawerPageOverlay = true,
  drawerCloseOnEsc= true,
  trigger = '.sn-trigger',
  triggerMode = 'toggle',
  animation = true,
  animationDuration= 150,
  width = 278,
  compactWidth = 96,
}) => {
  const [initiated, setInitiated] = useState(false);
  const [instance, setInstance] = useState(null);
  const sidenavRef = useRef(null);

  useEffect(() => {
    if (!initiated) {
      const sideNav = new Sidenav(sidenavRef.current, {
        compact,
        compactAutoExpandOnHover,
        compactAutoExpandDelay,
        drawer,
        direction,
        drawerPageOverlay,
        drawerCloseOnEsc,
        trigger,
        triggerMode,
        animation,
        animationDuration
      });
      setInitiated(true);

      if (!instance) {
        setInstance(sideNav);
      }
    }
  }, [
    initiated,
    instance,
    compact,
    compactAutoExpandOnHover,
    compactAutoExpandDelay,
    drawer,
    direction,
    drawerPageOverlay,
    drawerCloseOnEsc,
    trigger,
    triggerMode,
    animation,
    animationDuration
  ]);

  return (
    <SidenavContext.Provider value={{sidenav: instance}}>
      <aside
        ref={sidenavRef}
        data-sn-width={width}
        data-sn-compact-width={compactWidth}
        className={classnames('layout-content-fit', className)}
      >
        <div className="sn-wrap h-100">
          {children}
        </div>
      </aside>
    </SidenavContext.Provider>
  );
};

Nav.propTypes = propTypes;

export default Object.assign(Nav, {
  Header,
  Body,
  Footer,
  Menu: NavMenu
});