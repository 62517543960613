import React, {useContext, useState, useEffect, useRef} from 'react';
import classnames from "classnames";
import PropTypes from "prop-types";
import {useLocation, NavLink} from "react-router-dom";
import SideMenuLib from '../libs/SideMenu.lib'
import SidenavContext from '../context/sidenav';
import '../scss/_menu.scss'

const propTypes = {
  dark: PropTypes.bool,
  gutter: PropTypes.bool,
  itemsIndicators: PropTypes.bool,
  size: PropTypes.string
}

const defaultProps = {
  dark: false,
  gutter: true,
  itemsIndicators: true,
};

const ItemsGroup = ({ isSubMenu, className, children}) => {
  return (
    <div className={classnames('sn-menu-list', className, { 'sn-menu-item-sub': isSubMenu })}>
      {children}
    </div>
  );
};

const Title = ({children}) => {
  return (
    <div className={"sn-section-header"}>
      <div className={"sn-section-header-title"}>{children}</div>
    </div>
  );
};

// Menu Item
const Item = ({ isActive, to, href, icon, children }) => {
  const [active, setActive] = useState(isActive || false);
  const location = useLocation();
  const slots = Array.isArray(children) ? children : [children];
  const menuItemText = slots.find(slot => slot.type !== ItemsGroup);
  const subMenu = slots.find(slot => slot.type === ItemsGroup);

  useEffect(() => {
    setActive(location.pathname === to);
  }, [location, to]);

  const GoTo = ({ children, to, href }) => {
    if (to) {
      return (
        <NavLink to={to} className={`sn-menu-item-content`}>
          {children}
        </NavLink>
      );
    }

    return (
      <a href={href} className={`sn-menu-item-content`}>
        {children}
      </a>
    );
  }

  return (
    <div className={`sn-menu-item-wrap${active ? ' is-active' : ''}`}>
      <GoTo to={to} href={href}>
        {icon ? <span className="icon sn-menu-item-icon icon-s-24">{icon}</span> : ''}
        <span className="sn-menu-item-name">{menuItemText}</span>
      </GoTo>

      {subMenu}
    </div>
  );
};

const Menu = ({ className, children, sidenav, ...props }) => {
  const sidenavContext = useContext(SidenavContext);
  const SideMenuRef = useRef(null);

  const defineSize = () => {
    const sizes = {
      default: null,
      small: 'side-menu-sm',
      large: 'side-menu-lg',
    };
    return sizes[props.size] || sizes.default;
  }

  useEffect(() => {
    const sidenav = sidenavContext.sidenav;

    // If sidenav is initiated
    if (sidenav) {
      const sideMenu = new SideMenuLib(SideMenuRef.current, {}, sidenav);

      sidenav.onChange((state, newState) => {
        if (newState === 'expand') {
          // make side menu not compact
          sideMenu.sidemenu.classList.remove('side-menu-mini');
        }

        if (newState === 'compact') {
          sideMenu.sidemenu.classList.add('side-menu-mini');
          // make side menu compact
        }
      });

      // If the sidenav mode is compact, add side-menu-mini to the side menu
      if (sidenav.options.compact) {
        sideMenu.sidemenu.classList.add('side-menu-mini');
      }

    }
  }, [sidenavContext.sidenav]);

  return (
    <div
      id="sn-menu"
      ref={SideMenuRef}
      className={
        classnames(
        'side-menu',
        {
          'side-menu-on-dark': props.dark,
          'side-menu-no-gutter': !props.gutter,
          'side-menu-item-no-bg': !props.itemsIndicators,
        },
          defineSize(),
          className
      )}
    >
      {children}
    </div>
  );
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Object.assign(Menu, {
  ItemsGroup, Title, Item
});
