import React from "react";
import classnames from "classnames";

const LayoutBody = ({ className, style, children, ...props }) => {
  return (
    <main
      id={props.id}
      className={classnames(
        'layout-content layout-content-main',
        className
      )}
      style={props.style}
    >
      {children}
    </main>
  );
};

export default LayoutBody;
