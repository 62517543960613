import React from "react";
import classnames from "classnames";

const LayoutHeader = ({ className, children, ...props }) => {
  return (
    <div className={classnames('layout-header', className)} {...props}>
      {children}
    </div>
  );
};

export default LayoutHeader;
