export default class themeSwitch {
  constructor(el, options = {}) {
    this.options = Object.assign({}, {
      altThemeSelector: 'alt'
    }, options);

    this.el = ('string' === typeof el ) ? document.querySelector(el) : el;

    if (this.el) {
      this.initialize();
    }
  }

  initialize() {
    const htmlCL = document.getElementsByTagName('html')[0].classList;
    let isAlt = false;


    if (localStorage.getItem("is-alt-theme") === 'true') {
      htmlCL.add(this.options.altThemeSelector)
    }

    if (localStorage.getItem("is-alt-theme") === 'false') {
      htmlCL.remove(this.options.altThemeSelector)
    }

    // Check if Html tag has the 'alt' theme selector
    isAlt = htmlCL.contains(this.options.altThemeSelector);

    // Show switch icon passed on 'isAlt' data attribute
    this.el.querySelectorAll('.theme-switch-icon').forEach(icon => {
      if (isAlt) {
        if (icon.dataset.hasOwnProperty('isAlt')) {
          icon.style.display = 'flex';
        } else {
          icon.style.display = 'none';
        }
      } else {
        if (icon.dataset.hasOwnProperty('isAlt')) {
          icon.style.display = 'none';
        } else {
          icon.style.display = 'flex';
        }
      }
    });

    this.el.addEventListener('click', (e) => {
      isAlt = htmlCL.contains(this.options.altThemeSelector);
      this.el.classList.add('theme-switch-animate');

      this.el.querySelectorAll('.theme-switch-icon').forEach(icon => {
        if (isAlt) {
          htmlCL.remove(this.options.altThemeSelector);
          localStorage.setItem('is-alt-theme', 'false');

          if (!icon.dataset.hasOwnProperty('isAlt')) {
            icon.style.display = 'flex';
          } else {
            icon.style.display = 'none';
          }

        } else {
          htmlCL.add(this.options.altThemeSelector);
          localStorage.setItem('is-alt-theme', 'true');

          if (icon.dataset.hasOwnProperty('isAlt')) {
            icon.style.display = 'flex';
          } else {
            icon.style.display = 'none';
          }
        }
      })

      setTimeout(() => {
        this.el.classList.remove('theme-switch-animate');
      }, 200)
    })

    document.querySelector('body').classList.remove('invisible');
  }
}
