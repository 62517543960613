import React from "react";
import {Layout} from "../@dlex/components";

const BlankLayout = ({children}) => {
  return (
      <> {children} </>

  )
}

export default BlankLayout;
