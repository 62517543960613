import React, {useEffect, useState} from 'react';
import AccordionSidebar from "./Dropdowns";
import {Container, Dropdown} from "react-bootstrap"
import "./styles.css"
import {getUserInfo} from "../../../pages/shared/functions/pocketbase";





const Sidebar = () => {
    const [dropdownTitle, setDropdownTitle] = useState('Pick a Phishing Link');
    const [user, setUser] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const userInfo = await getUserInfo();
            setUser(userInfo);
        }

        fetchData();
    }, []);
    const handleSelect = async (e) => {
        try {
            await navigator.clipboard.writeText(e);
            setDropdownTitle('Copied!');
            setTimeout(() => {
                setDropdownTitle('Pick a Phishing Link');
            }, 3000);  // Change back after 3 seconds
        } catch (err) {
            alert('Failed to copy link');
        }
    }
    return (
        <Container fluid className="d-flex flex-column"
                   style={{height: "100%", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none"}}>


            <AccordionSidebar user={user}/>
            <div style={{textDecoration: "none", marginTop: "auto"}}>
                <Dropdown onSelect={handleSelect} style={{alignSelf: "center", width: "100%"}}>
                    <Dropdown.Toggle variant="soft-secondary" id="dropdown-basic">
                        {dropdownTitle}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={"https://" + window.location.hostname + "/help"}>/help</Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"https://" + window.location.hostname + "/support"}>/support</Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"https://" + window.location.hostname + "/verify"}>/verify</Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"https://" + window.location.hostname + "/reset-password"}>/reset-password</Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"https://" + window.location.hostname + "/ticket"}>/ticket</Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"https://" + window.location.hostname + "/ticket?#153937"}>/ticket?#153937</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </Container>
    );
};


export default Sidebar;
