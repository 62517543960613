const useSlots = (children, components) => {
  const slots = children ? Array.isArray(children) ? children : [children] : [];
  const fComponents = {};

  slots.forEach(slot => {
    let name;
    let component;

    Object.keys(components).forEach(componentName => {
      const com = components[componentName];

      if (com === slot.type) {
        name = componentName;
        component = slot;
      }
    });

    if (component) {
      fComponents[name] = component;
    }
  });

  return fComponents;
};

export default useSlots;
