import loadable from '@loadable/component';

const pages = [
  {
    path: '/connect',
    exact: true,
    layout: "connect",
    requiresAuth: true,
    component: loadable(() => import('./pages/Admin/Main'))
  },
  {
    path: '/',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Admin/Login/login.jsx'))
  },
  {
    path: '/register',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Admin/Login/register.jsx'))
  },
  {
    path: '/wait',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Admin/Login/wait.jsx'))
  },
  {
    path: '/docs',
    exact: true,
    layout: "default",
    requiresAuth: true,
    component: loadable(() => import('./pages/Admin/docs'))
  },
  {
    path: '/test',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
    {
    path: '/verify',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
    {
    path: '/help',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
    {
    path: '/support',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
    {
    path: '/ticket',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
    {
    path: '/reset-password',
    exact: true,
    layout: "default",
    requiresAuth: false,
    component: loadable(() => import('./pages/Victim/Main'))
  },
]

export default pages;
