import PocketBase from "pocketbase";




export const getUserInfo = async () => {
  const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
  const userModel = pb.authStore.model;
  if (!userModel) return null;
  try {
    const userRecord = await pb.collection('users').getFirstListItem(`id="${userModel.id}"`);
    if (userRecord.id === userModel.id) {
      return {
        username: userModel.username,
        id: userModel.id,
        categories: userRecord.categories || userModel.categories || "",
        trusted: userModel.Trusted
      };
    } else {
      window.location.href = "/"
    }
  } catch (error) {
    window.location.href = "/"
  }
};





export const register = async (username, password) => {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
    try {
        const data = {
            "username": username,
            "password": password,
            "passwordConfirm": password,
        };
        await pb.collection('users').create(data);
        return true
    } catch (error) {
        return false;
    }
};

export const write_log = async (cat, site) => {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
    try {
        const userdata = await getUserInfo();
        const data = {
            "User": userdata.username,
            "Category": cat,
            "Page": site
        };
        await pb.collection('logs').create(data);
    } catch (e) {
        console.error(e);
    }
}

export const isAllowedToDomain = async (config) => {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
    try {
        const userdata = await getUserInfo(config);
        const domain = await pb.collection('domains').getFullList({ filter: `name = '${window.location.hostname}'`});
        return domain.length > 0 && domain.some(item => item.Owner.includes(userdata.id));
    } catch (error) {
        return false;
    }
};


export async function isUsernameTaken(username) {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
  try {
    const userList = await pb.collection('domains').getFullList({ filter: `name = '${username}'`, limit: 1 });
    console.log("Initial Setup? " + (!userList.length > 0))
    return userList.length > 0;
  } catch (error) {
    if (error.status === 404) {
        console.log(error)
      return false;
    }
    throw error;
  }

}
export const setDomainOwner = async () => {
    const pb = new PocketBase(window.location.protocol + '//' + window.location.hostname);
    const user = await getUserInfo()
    try {
        const data2 = {
            "name": window.location.hostname,
            "Owner": user.id,
        };
        await pb.collection('domains').create(data2);
        return true
    } catch (error) {
        return false;
    }
};



