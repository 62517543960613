import React from "react";
import classnames from "classnames";
import useSlots from "../../hooks/useSlots";
import { LayoutHeader, Nav, LayoutBody, LayoutFooter } from './slots'
import './layout.scss'

const Layout = ({children, className, ...props}) => {
  const {
    layoutHeader,
    nav,
    layoutBody,
    layoutFooter,
  } = useSlots(children, {layoutHeader: LayoutHeader, nav: Nav, layoutBody: LayoutBody, layoutFooter: LayoutFooter})

  return (
    <div
      className={classnames(
        className,
        'layout-wrapper layout-content-horizontal',
        props.boxed ? 'container' : '',
      )}
    >
      {layoutHeader}
      <div
        className={classnames(
          className,
          'layout-content layout-content-vertical'
        )}
      >
        {nav}
        {layoutBody}
      </div>
      {layoutFooter}
    </div>
  )
}

export default Object.assign(Layout, {
  Header: LayoutHeader,
  Body: LayoutBody,
  Footer: LayoutFooter,
  Nav: Nav
});
