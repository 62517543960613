export default class Sidenav {
  /**
   * Sticky Sidebar Constructor.
   * @constructor
   * @param {HTMLElement|String} sidenav - The sidenav element selector.
   * @param {Object} options - The options of sidenav.
   */
  constructor(sidenav, options = {}) {
    // Sidebar element query if there's no one, throw error.
    this.sidenav = (typeof sidenav === 'string' ) ? document.querySelector(sidenav) : sidenav;

    // Define options and apply defaults
    this.options = Object.assign({}, {
      classes: {
        wrapper: 'sidenav',
        compact: 'sn-compact',
        hidden: 'sn-hidden',
        drawerClose: 'sn-drawer-off',
        drawerOpen: 'sn-drawer-on',
        autoExpand: 'sn-auto-expand',
        autoExpandOn: 'sn-auto-expand-on',
        autoExpandOff: 'sn-auto-expand-off',
        enterActive: 'enter-active',
        leaveActive: 'leave-active',
        pageOverlay: 'sn-page-overlay'
      },
      dimensions: {
        default: this.sidenav.getAttribute('data-sn-width') || 280,
        compact: this.sidenav.getAttribute('data-sn-compact-width') || 88,
        drawer: this.sidenav.getAttribute('data-sn-drawer-width') || 280
      },
      relativeTo: 'html',
      pattern: null,
      compact: false,
      compactAutoExpandOnHover: false,
      compactAutoExpandDelay: 300,
      compactSideContentSelector: '.layout-content-main',
      drawer: false,
      // drawerTopSpace: 0,
      // drawerTopSpaceSelector: false,
      drawerPageOverlay: true,
      drawerCloseOnEsc: true,
      drawerClose: undefined,
      trigger: '.sn-trigger',

      // triggerMode is active only when drawer mode is off
      triggerMode: 'toggle', // compact, toggle
      animation: true,
      animationDuration: 150,
      direction: 'left'
    }, options);

    this.callbacks = {
      onChange: []
    };

    this.timeouts = {
      'sidenav': {enter: undefined, leave: undefined},
      'overlay': {enter: undefined, leave: undefined},
    };
    this.events = {};
    this.drawerInitiated = false;

    if (this.sidenav) {
      this.initialize();
    }
  }

  initialize() {
    const self = this;

    // Remove any inline styles
    this.sidenav.removeAttribute('style')

    // Add default sidenav class to sidebar
    this.sidenav.classList.add(this.options.classes.wrapper);

    // Define dimensions
    self.updateWidth()
    self.handleDrawerBreakpoint(self)();

    // Apply styling to sidenav on first print
    document.addEventListener('DOMContentLoaded', function (e) {
      // Define dimensions
      self.updateWidth()

      // Handle Drawer mode for responsive
      if (!self.options.drawer && self.options.drawerBreakpoint !== undefined && self.options.drawerBreakpoint > 0) {
        self.handleDrawerBreakpoint(self)(e);
      }
    });

    // Handle events when resizing
    window.addEventListener('resize', function (e) {
      // Handle Drawer mode for responsive
      if (self.options.drawerBreakpoint !== undefined && self.options.drawerBreakpoint > 0) {
        self.handleDrawerBreakpoint(self)(e);
      }
    });

    // Compact state
    if (this.options.compact) {
      this.compact();
    }

    // Activate Drawer mode
    if (this.options.drawer) {
      this.initDrawer();
    }

    if (this.options.compactAutoExpandOnHover && !this.options.drawer) {
      this.initAutoExpand();
    }

    // Setup trigger element
    // if (this.options.trigger && this.options.trigger.length > 0 && !this.options.compactAutoExpandOnHover) {
    if (this.options.trigger && this.options.trigger.length > 0) {
      this.initTrigger();
    }

    // Initiate drawer closer event
    if (this.options.drawerClose !== undefined) {
      this.initDrawerCloser();
    }
  }

  // Register a callback to be triggered when the sidenav state changes
  onChange(cb) {
    if (typeof cb === 'function') {
      this.callbacks.onChange.push(cb);
    }
  }
  // Runs all callbacks of a certain type
  triggerCallback(type, ...args) {
    if (this.callbacks[type] !== undefined) {
      this.callbacks[type].forEach(cb => {
        cb(this.state(), ...args);
      })
    }
  }

  getCurrentWidth() {
    return this.options.compact ? this.options.dimensions.compact : this.options.dimensions.default;
  }

  updateWidth() {
    this.addStyleToElem(this.sidenav, {
      'width': `${this.getCurrentWidth()}px`,
      'max-width': `${this.getCurrentWidth()}px`,
    })
  }
  handleDrawerBreakpoint (self) {
    return (e) => {
      // enable drawer if breakpoint is the same or smaller than the window width
      if (self.options.drawerBreakpoint >= window.innerWidth) {
        if (!self.drawerInitiated) {
          self.wasInCompact = self.hasClass(self.sidenav, self.options.classes.compact)
          self.initDrawer();
          self.options.drawer = true;
          self.drawerInitiated = true
        }
      } else {
        if (self.drawerInitiated) {
          self.wasInCompact = self.hasClass(self.sidenav, self.options.classes.compact)
          self.destroyDrawer();

          if (self.wasInCompact) {
            self.compact();
            self.triggerCallback('onChange', 'compact');
          } else {
            self.expand();
            self.triggerCallback('onChange', 'expand');
          }

          self.options.drawer = false;
          self.drawerInitiated = false;
        }
      }
    };
  }

  initDrawerCloser() {
    const drawerCloser = ('string' === typeof this.options.drawerClose) ? document.querySelector(this.options.drawerClose) : this.options.drawerClose;

    if (drawerCloser) {
      drawerCloser.addEventListener('click', (e) => {
        this.animate('sidenav', this.sidenav, 'leave');
        this.closeDrawer();
        this.removePageOverlay();
      });
    }
  }

  initTrigger() {
    const triggerButton = ('string' === typeof this.options.trigger) ? document.querySelector(this.options.trigger) : this.options.trigger;

    if (triggerButton) {
      triggerButton.addEventListener('click', (e) => {
        // If drawer mode is on and drawer is closed, open the drawer
        if (this.options.drawer) {
          if (this.state() === 'drawerClose') {
            this.animate('sidenav', this.sidenav, 'enter');
            this.openDrawer();
            this.createPageOverlay();
          } else {
            this.animate('sidenav', this.sidenav, 'leave');
            this.closeDrawer();
            this.removePageOverlay();
          }
        } else {
          // Handle toggling compact mode
          if (this.options.triggerMode === 'compact') {
            if (this.state() === 'expand') {
              this.compact();
            } else {
              this.expand();
            }
          } else {
            // Handle toggle mode
            if (this.state() === 'hidden') {
              this.show();
            } else {
              this.hide();
            }
          }
        }
      });
    }
  }

  applyPosition() {
    let relativeTo = document.querySelector(this.options.relativeTo)
    if (!relativeTo) {
      relativeTo = document.querySelector('html')
    }

    this.addStyleToElem(this.sidenav, {
      [this.options.direction]: '0px',
      height: `${relativeTo.offsetHeight - this.sidenav.offsetTop}px`
    })
  }

  removePosition() {
    this.delStyleFromElem(this.sidenav, [this.options.direction, 'height'])
  }

  initAutoExpand() {
    // Add default classes
    this.sidenav.classList.add(this.options.classes.autoExpand);
    this.sidenav.classList.add(this.options.classes.autoExpandOff);

    let mouseEnter, mouseLeave;

    // On mouse enter
    this.sidenav.addEventListener("mouseenter", e => {
      if (this.state() !== 'compact') {
        return;
      }

      clearTimeout(mouseLeave)

      mouseEnter = setTimeout(() => {
        // update width
        this.options.compact = false;
        this.addStyleToElem(document.querySelector(this.options.compactSideContentSelector), {
          [`margin-${this.options.direction}`]: `${this.options.dimensions.compact}px`
        })
        this.applyPosition();
        this.autoExpandOn();
        this.addStyleToElem(
          this.sidenav.querySelector('.sn-wrap'), {
            'width': `${this.options.dimensions.default}px`,
          }
        )
        this.addStyleToElem(this.sidenav, {
          'width': `${this.options.dimensions.default}px`,
          'max-width': `${this.options.dimensions.default}px`,
        })
      }, this.options.compactAutoExpandDelay);
    });

    // On mouse leave
    this.sidenav.addEventListener("mouseleave", e => {
      if (this.state() !== 'compact') {
        return;
      }

      clearTimeout(mouseEnter)

      if (this.hasClass(this.sidenav, this.options.classes.autoExpandOn)) {
        this.options.compact = true;

        this.addStyleToElem(this.sidenav, {
          'width': `${this.options.dimensions.compact}px`,
          'max-width': `${this.options.dimensions.compact}px`,
        })
        setTimeout(() => {
          this.removePosition();
          this.delStyleFromElem(this.sidenav.querySelector('.sn-wrap'), ['width', 'max-width'])
          this.delStyleFromElem(document.querySelector(this.options.compactSideContentSelector), [
            `margin-${this.options.direction}`
          ])
          this.autoExpandOff();
        }, this.options.animationDuration)
      }
    });
  }

  destroyDrawer() {
    this.sidenav.classList.remove(this.options.classes.drawerClose)
    this.sidenav.classList.remove(this.options.classes.drawerOpen)
    this.removePageOverlay()
    this.delStyleFromElem(this.sidenav, ['transform'])

    if (this.events.drawermousedownevent !== undefined) {
      window.removeEventListener('mousedown', this.drawer.drawermousedownevent)
    }

    if (this.events.drawerkeydownevent !== undefined) {
      window.removeEventListener('keydown', this.drawer.drawerkeydownevent)
    }
  }

  getTransform(openState, forCompact) {
    const sidebarWidth = forCompact ? this.options.dimensions.compact : this.options.dimensions.default

    if (openState) {
      return this.options.direction === 'right' ? `translateX(0px)` : `translateX(0px)`;
    }

    return this.options.direction === 'right' ? `translateX(${sidebarWidth}px)` : `translateX(-${sidebarWidth}px)`;
  }

  initDrawer() {
    // Define direction for element in drawer mode only
    this.addStyleToElem(this.sidenav, {
      [this.options.direction]: 0,
    })

    // Make the drawer closed by default
    this.closeDrawer();

    // Force to close the drawer when click outside
    this.events.drawermousedownevent = window.addEventListener('mousedown', e => {
      if (!this.sidenav.contains(e.target) && this.state() === 'drawerOpen') {
        this.animate('sidenav', this.sidenav, 'leave');
        this.closeDrawer();
        this.removePageOverlay();
      }
    });

    // Allow escape key
    if (this.options.drawerCloseOnEsc) {
      this.events.drawerkeydownevent = window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
          this.animate('sidenav', this.sidenav, 'leave');
          this.closeDrawer();
          this.removePageOverlay();
        }
      });
    }
  }

  openDrawer() {
    this.wasInCompact = this.hasClass(this.sidenav, this.options.classes.compact)

    // make sure to expand if in compact mode but not configured to be compact
    if (this.wasInCompact && this.options.compact === false) {
      this.expand();
    }

    this.addStyleToElem(this.sidenav, {
      'transform': this.getTransform(true)
    })
    this.replaceClass(this.sidenav, this.options.classes.drawerClose, this.options.classes.drawerOpen);
    this.triggerCallback('onChange', 'drawerOpen');
  }

  closeDrawer() {
    if (this.wasInCompact) {
      this.compact();
    }

    // Define direction for element in drawer mode only
    this.addStyleToElem(this.sidenav, {
      'transform': this.getTransform(false)
    })
    this.replaceClass(this.sidenav, this.options.classes.drawerOpen, this.options.classes.drawerClose);
    this.triggerCallback('onChange', 'drawerClose');
  }

  hide() {
    this.sidenav.classList.add(this.options.classes.hidden);
    this.triggerCallback('onChange', 'hidden');
  }

  show() {
    this.sidenav.classList.remove(this.options.classes.hidden);
    this.triggerCallback('onChange', 'shown');
  }

  compact() {
    if (!this.hasClass(this.sidenav, this.options.classes.compact)) {
      this.delStyleFromElem(this.sidenav.querySelector('.sn-wrap'), ['width', 'max-width'])
      this.addStyleToElem(this.sidenav, {
        'width': `${this.options.dimensions.compact}px`,
        'max-width': `${this.options.dimensions.compact}px`
      })
      this.sidenav.classList.add(this.options.classes.compact);
      this.triggerCallback('onChange', 'compact');
    }
  }

  expand() {
    if (this.hasClass(this.sidenav, this.options.classes.compact)) {
      this.delStyleFromElem(this.sidenav.querySelector('.sn-wrap'), ['width', 'max-width'])
      this.addStyleToElem(this.sidenav, {
        'width': `${this.options.dimensions.default}px`,
        'max-width': `${this.options.dimensions.default}px`
      })
      this.sidenav.classList.remove(this.options.classes.compact);
      this.triggerCallback('onChange', 'expand');
    }
  }

  autoExpandOn() {
    this.replaceClass(this.sidenav, this.options.classes.autoExpandOff, this.options.classes.autoExpandOn);
    this.triggerCallback('onChange', 'expand');
  }

  autoExpandOff() {
    this.replaceClass(this.sidenav, this.options.classes.autoExpandOn, this.options.classes.autoExpandOff);
    this.triggerCallback('onChange', 'compact');
  }

  state() {
    if (this.hasClass(this.sidenav, this.options.classes.drawerClose)) {
      return 'drawerClose'
    }
    if (this.hasClass(this.sidenav, this.options.classes.drawerOpen)) {
      return 'drawerOpen'
    }
    if (this.hasClass(this.sidenav, this.options.classes.hidden)) {
      return 'hidden'
    }
    if (this.hasClass(this.sidenav, this.options.classes.compact)) {
      return 'compact'
    }
    if (!this.hasClass(this.sidenav, this.options.classes.compact)) {
      return 'expand'
    }
  }

  isCompact() {
    if (this.options.compactAutoExpandOnHover) {
      return this.hasClass(this.sidenav, this.options.classes.compact) && !this.hasClass(this.sidenav, this.options.classes.autoExpandOn);
    } else {
      return this.hasClass(this.sidenav, this.options.classes.compact);
    }
  }

  /**
   * Create page overlay
   */
  createPageOverlay() {
    if (this.options.drawerPageOverlay) {
      const el = document.createElement('div');
      el.className = this.options.classes.pageOverlay;
      document.body.appendChild(el);
      this.animate('overlay', el, 'enter')
    }
  }

  /**
   * Remove page overlay
   */
  removePageOverlay() {
    if (this.options.drawerPageOverlay) {
      const el =  document.querySelector(`.${this.options.classes.pageOverlay}`);
      if (el !== null) {
        // Apply animation if enabled and remove the element after animation is completed.
        this.animate('overlay', el, 'leave', () => {
          document.body.removeChild(el);
        });

        // Otherwise remove the element immediately.
        if (!this.options.animation) {
          document.body.removeChild(el);
        }
      }
    }
  }

  /**
   * Apply animation behavior to element
   *
   * @param {String} key - Unique key for each element
   * @param {Element} el - Element selector
   * @param {('enter'|'leave')} state - Define animation state
   * @param {Function=} callback - callback function to be executed after animation is completed.
   */
  animate(key, el, state ,callback) {
    if (el !== null && this.options.animation) {
      const animationClass = {
        enter: this.options.classes.enterActive,
        leave: this.options.classes.leaveActive,
      };
      const animationNewState = animationClass[state];

      // if timeout state is enter, clear leave
      clearTimeout(state === 'enter' ? this.timeouts[key].leave : this.timeouts[key].enter);

      el.classList.add(animationNewState);

      // reset animation state
      const classToRem = state === 'enter' ? this.options.classes.leaveActive : this.options.classes.enterActive;
      const classToAdd = state === 'enter' ? this.options.classes.enterActive : this.options.classes.leaveActive;
      if (this.hasClass(el, classToRem)) {
        el.classList.remove(classToRem);
      }
      el.classList.add(classToAdd);

      this.addStyleToElem(el, {
        'animation-duration': `${this.options.animationDuration}ms`,
        'transition-duration': `${this.options.animationDuration}ms`
      });

      // Initiate animation with timeout
      this.timeouts[key][state] = setTimeout(() => {
        el.classList.remove(animationNewState);
        this.delStyleFromElem(el, ['animation-duration', 'transition-duration']);
        if (callback && typeof callback === 'function') {
          callback();
        }
      }, this.options.animationDuration);
    }
  }

  /**
   * Check if class exists
   * @param {Element} element - Element selector
   * @param {String} className - Class name you want to check
   * @returns {boolean}
   */
  hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
  }

  /**
   * Replace old class with new one
   * @param {Element} el - Element selector
   * @param {String} oldClass
   * @param {String} newClass
   */
  replaceClass(el, oldClass, newClass) {
    el.classList.remove(oldClass);
    el.classList.add(newClass);
  }

  addStyleToElem(el, style) {
    Object.keys(style).forEach(key => {
      el.style[key] = style[key];
    });
  }

  delStyleFromElem(el, properties) {
    properties.forEach(key => {
      el.style.removeProperty(key);
    });
  }
}
