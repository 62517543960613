export default class sidenavMenu {
  constructor(selector, options, sidenav) {
    // Sidebar element query if there's no one, throw error.
    this.sidemenu = (typeof selector === 'string') ? document.querySelector(selector) : selector;
    if (!this.sidemenu) {
      console.warn('side menu', selector, 'is not found')
      return
    }

    this.options = Object.assign({}, {
      classes: {
        menuItem: 'sn-menu-item-wrap',
        menuItemHasSub: 'sn-menu-item-has-sub',
        subMenu: 'sn-menu-item-sub',
        activeMenuItem: 'is-active',
        menuItemSubMenuOpen: 'sn-sub-menu-open',
        menuItemSubMenuClose: 'sn-sub-menu-close',
        menuItemContent: 'sn-menu-item-content'
      },
    }, options);

    this.sidenav = sidenav || {
      onChange () {},
      isCompact () {
        return false;
      }
    };
    this.menuItems = [];

    setTimeout(() => {
      this.init();
    }, 0)
  }

  init() {
    this.addStyleToElem(this.sidemenu, {
      visibility: 'hidden'
    })
    // Refresh local state with menu items
    this.refresh();

    this.addStyleToElem(this.sidemenu, {
      visibility: 'visible'
    })

    // Update sub menus visibility based on sidebar changes
    if (this.sidenav) {
      this.sidenav.onChange((changeType) => {
        this.menuItems.filter(n => n.sub).forEach((n) => {
          this.updateMenuItemState(n.item, n.sub);
        });
      });
    }

    // Close all sub menus if clicked outside
    window.addEventListener('click', e => {
      const ctx = this;

      // Go through open sub menus only
      if (ctx.sidenav && ctx.sidenav.isCompact()) {
        this.menuItems.forEach((n) => {
          if (n.sub && ctx.isSubmenuOpen(n.item) && !n.sub.contains(e.target) && !n.item.contains(e.target)) {
            ctx.hideSubmenu(n.item, n.sub);
          }
        });
      }
    });

    // Handle each menu item
    if (!this.sidemenu.classList.contains('side-menu-compact') && !this.sidemenu.classList.contains('side-menu-mini')) {
      this.menuItems.forEach((n) => {
        const ctx = this,
          menuItem = n.item,
          subMenu = n.sub;

        // register click handler for menu items
        menuItem.querySelector(`.${this.options.classes.menuItemContent}`).addEventListener('click', (e) => {
          if (subMenu) {
            this.menuItemClicked(e, menuItem, subMenu);
          }

          if (typeof ctx.options.onItemClick === 'function') {
            ctx.options.onItemClick(e, menuItem);
          }
        });

        // Handle situations when there is a sub menu
        if (subMenu) {
          // Handle inital state of menuItem and subMenu, by checking
          // if a subMenu item is active, then make the menuItem active
          // Otherwise, hide the sub menu.
          this.updateMenuItemState(menuItem, subMenu);

          // register click handler for sub menu items
          subMenu.querySelectorAll(`.${this.options.classes.menuItem}`).forEach(subMenuItem => {
            subMenuItem.querySelector(`.${this.options.classes.menuItemContent}`).addEventListener('click', (e) => {
              if (ctx.sidenav.isCompact()) {
                ctx.hideSubmenu(menuItem, subMenu);
              }

              if (typeof ctx.options.onItemClick === 'function') {
                ctx.options.onItemClick(e, subMenuItem);
              }
            });
          });
        }
      });
    }
  }

  refresh() {
    this.sidemenu.querySelectorAll(`.${this.options.classes.menuItem}`).forEach((menuItem => {
      const subMenu = menuItem.querySelector(`.${this.options.classes.subMenu}`);
      if (subMenu) {
        const subMenuItems = subMenu.querySelector(`.${this.options.classes.menuItem}`);
        subMenu.setAttribute('data-sub-height', subMenu.offsetHeight)
        this.addClassesToElem(menuItem, [this.options.classes.menuItemHasSub])
        this.menuItems.push({
          item: menuItem,
          sub: subMenuItems ? subMenu : null
        });
      }
    }));
  }

  subMenuHasActiveItem(subMenu) {
    return subMenu && subMenu.querySelector(`.${this.options.classes.menuItem}.${this.options.classes.activeMenuItem}`) !== null;
  }

  hideSubmenu(menuItem, subMenu) {
    this.addClassesToElem(menuItem, [this.options.classes.menuItemSubMenuClose]);
    this.removeClassesFromElem(menuItem, [this.options.classes.menuItemSubMenuOpen]);

    if (!this.subMenuHasActiveItem(subMenu)) {
      this.removeClassesFromElem(menuItem, [this.options.classes.activeMenuItem]);
    }

    this.addStyleToElem(subMenu, {height: `0px`, display: 'none'});
  }

  showSubmenu(menuItem, subMenu) {
    this.addClassesToElem(menuItem, [this.options.classes.menuItemSubMenuOpen, this.options.classes.activeMenuItem]);
    this.removeClassesFromElem(menuItem, [this.options.classes.menuItemSubMenuClose]);

    // subMenu.getAttribute('data-sub-height')
    this.addStyleToElem(subMenu, {height: `auto`, display: 'block'});
    // this.addStyleToElem(subMenu, {height: `${subMenu.offsetHeight}px`, visibility: 'visible'});
  }

  isSubmenuOpen(menuItem) {
    return menuItem.className.split(' ').indexOf(this.options.classes.menuItemSubMenuOpen) > -1;
  }

  updateMenuItemState(menuItem, subMenu) {
    // Indicate sub menu is active state
    const subMenuIsActive = this.subMenuHasActiveItem(subMenu);

    // Hide sub menu if the subMenu it self is not active or if the sidebar is in compact mode
    if (!subMenuIsActive || this.sidenav.isCompact()) {
      this.hideSubmenu(menuItem, subMenu);
    }

    // Show the submenu if it has an active item and the sidenav is not compact
    if (subMenuIsActive && !this.sidenav.isCompact()) {
      this.showSubmenu(menuItem, subMenu);
    }

    // Apply active state to menu item if a sub menu item is active
    if (subMenuIsActive) {
      this.addClassesToElem(menuItem, [this.options.classes.activeMenuItem]);
    }
  }

  menuItemClicked(e, menuItem, subMenu) {
    const ctx = this;
    const menuItemOpen = ctx.isSubmenuOpen(menuItem);

    // hide all menu items by default if in compact mode
    this.menuItems.forEach((n) => {
      if (n.sub && ctx.sidenav.isCompact()) {
        ctx.hideSubmenu(n.item, n.sub);
      }
    });

    // Handle updating the clicked menu item
    if (menuItemOpen) {
      ctx.hideSubmenu(menuItem, subMenu);
    } else {
      ctx.showSubmenu(menuItem, subMenu);
    }

    e.preventDefault();
  }

  addStyleToElem(elem, style) {
    Object.keys(style).forEach(key => {
      elem.style[key] = style[key];
    });
  }

  addClassesToElem(elem, classes) {
    classes.forEach(_class => {
      elem.classList.add(_class);
    });
  }

  removeClassesFromElem(elem, classes) {
    classes.forEach(_class => {
      elem.classList.remove(_class);
    });
  }
}
