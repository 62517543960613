import React from "react";
import { Link } from 'react-router-dom';
import { Navbar } from "react-bootstrap";
import { MdMenu } from "react-icons/md";
import Sidebar from "./Sidebar/Admin/Sidebar"
import {Layout, ThemeSwitch} from "../@dlex/components";

const Default = ({children}) => {
  return (

    <Layout>
      <Layout.Header>
        {/* Top Navbar */}
        <Navbar variant="theming" expand="lg">
          <div className="container-fluid gx-4">
            <div className="navbar-content-first">
              <Navbar.Toggle className={"sn-trigger btn-icon btn-subtle-light rounded-circle me-3"}>
                <MdMenu size={24} className={"icon"} />
              </Navbar.Toggle>


              <Link to="/" className="navbar-brand me-0">
                  <span className="ms-2" style={{fontSize:"20px"}}><b>PLTG</b> <b style={{color:"var(--primary)"}}>Panel</b></span>
              </Link>

            </div>
            <div className="navbar-content-last">
              <ThemeSwitch />
            </div>
          </div>
        </Navbar>
      </Layout.Header>
      {/* Main Content */}
      <Layout.Body id={"main-content"}>
        {children}
      </Layout.Body>
      {/* Side Navigation */}
      <Layout.Nav className={"bg-base-1"} drawerBreakpoint={992} compact={false} compactAutoExpandOnHover={false}>
        <Layout.Nav.Body>
          <Sidebar />
        </Layout.Nav.Body>
      </Layout.Nav>
    </Layout>
  )
}

export default Default;
